import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Theme from '../theme/theme';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SEO from '../components/seo';

const Layout = ({ children }) => (
    <ThemeProvider theme={Theme}>
        <SEO />
        <CssBaseline />
        <Header />
        {children}
        <Footer />
    </ThemeProvider>
);

export default Layout;
