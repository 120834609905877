import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box } from '@mui/material';

export const services = [
    {
        name: 'Nubi Compute Service',
        logo: '../images/computer-service.svg',
        description: 'Creá tus máquinas virtuales en segundos',
        key: 1,
        url_key: 'nubi_compute_service',
    },
    {
        name: 'Flexible Block Storage',
        logo: '../images/privarte-cloud.svg',
        description: 'Almacenamiento adicional para tus máquinas virtuales',
        key: 2,
        url_key: 'flexible_block_storage',
    },
    {
        name: 'VPC',
        logo: '../images/bandwidth.svg',
        description: 'Segmentá tus servidores en distintas redes',
        key: 3,
        url_key: 'vpc',
    },
    {
        name: 'Cloud Firewall and VPN',
        logo: '../images/object-storage.svg',
        description: 'Conectate de manera segura a tu nube',
        key: 4,
        url_key: 'cloud_firewall_and_vpn',
    },
    {
        name: 'Object Storage Service',
        logo: '../images/computer-service.svg',
        description: 'Almacenamiento en Buckets compatible con S3',
        key: 5,
        url_key: 'object_storage_service',
    },
    {
        name: 'Nubi Monitoring Service',
        logo: '../images/cloud-vpn.svg',
        description: 'Observá tu infraestructura desde un solo lugar',
        key: 6,
        url_key: 'nubi_monitoring_service',
    },
    {
        name: 'Custom Images',
        logo: '../images/monitoring.svg',
        description: 'Subí tus imágenes a medida para agilizar tus despliegues',
        key: 7,
        url_key: 'custom_images',
    },
    {
        name: 'Dedicated Bandwidth',
        logo: '../images/computer-service.svg',
        description: 'Enlace dedicado para tu proyecto',
        key: 8,
        url_key: 'dedicated_bandwidth',
    },

    {
        name: 'CI/CD DevOps',
        logo: '../images/migration.svg',
        description: 'Servicio de DevOps provisto por nuestros ingenieros',
        key: 9,
        url_key: 'ci_cd_devops',
    },
    {
        name: 'Nubi Migration Service',
        logo: '../images/storage.svg',
        description: 'Migración de datos y aplicaciones 100% gratis',
        key: 10,
        url_key: 'nubi_migration_service',
    },
];

export const solutions = [
    {
        name: 'Private Cloud',
        description: 'Ambiente de nube dedicado para tu empresa',
        key: 1,
        url_key: 'private_cloud',
        image: (<Box style={{ width: '100px' }}><StaticImage src="../images/private-cloud.svg" alt="Nube" /></Box>),
    },
    {
        name: 'Colocation',
        description: 'Alojá tus servidores en nuestro Datacenter',
        key: 2,
        url_key: 'colocation',
        image: (<StaticImage src="../images/colocation_icon.svg" alt="Colocation" />),
    },
    {
        name: 'SAP',
        description: 'Infraestructura dedicada para HANA o R/3',
        key: 3,
        url_key: 'sap',
        image: (<StaticImage src="../images/sap_icon.svg" alt="SAP" />),
    },
    {
        name: 'Networking',
        description: 'Servicio on-premise para conectar tus sitios a la nube',
        key: 4,
        url_key: 'networking',
        image: (<Box style={{ width: '100px' }}><StaticImage src="../images/networking_icon.svg" alt="Networking" /></Box>),
    },
    {
        name: 'Cloud PBX',
        description: 'Central telefónica VoIP en la nube',
        key: 5,
        url_key: 'cloud_pbx',
        image: (<Box style={{ width: '100px' }}><StaticImage src="../images/cloudpbx_icon.svg" alt="Cloud PBX" /></Box>),
    },
];

export const useIntersection = (ref) => {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting),
            { threshold: 1 },
        );

        observer.observe(ref.current);
        return () => { observer.disconnect(); };
    }, [ref]);

    return isIntersecting;
};
