export default {
    '/about_us': 'Servicios de nube pública y privada en Argentina con principal foco en el servicio',
    '/contact_us': '¿Querés empezar con servicios en la nube pero no sabés que necesitás? ¡Comunicate con nuestro equipo!',
    '/pricing': 'Calculador de precios simple para tu infraestructura en la nube',
    '/services/nubi_compute_service': 'Creá y corré las aplicaciones de tu negocio en máquinas virtuales en la nube siempre disponibles',
    '/services/flexible_block_storage': 'Aumentá la capacidad de almacenamiento de tus máquinas virtuales con volúmenes en la nube de alta velocidad',
    '/services/vpc': 'Redes privadas en la nube para segmentar tus máquinas virtuales de forma segura',
    '/services/cloud_firewall_and_vpn': 'Firewall virtual y VPN en la nube para agregar seguridad los accesos de tus aplicaciones',
    '/services/object_storage_service': 'Object Storage compatible con s3 para almacenar todo tipo de datos',
    '/services/nubi_monitoring_service': 'Monitoreo centralizado de tus máquinas virtuales en la nube',
    '/services/custom_images': 'Subí tus imágenes personalizadas a la nube para crear máquina virtuales al instante',
    '/services/dedicated_bandwidth': 'Ancho de banda dedicado para tu VPC y aplicaciones',
    '/services/ci_cd_devops': 'Automatizá despliegues en la nube. Servicio de DevOps para que te enfoques en el desarrollo',
    '/services/nubi_migration_service': 'Servicio de migración a la nube de aplicaciones y datos. Definí qué, despreocupate del cómo',
    '/solutions/private_cloud': 'Nube privada en alta disponibilidad y dedicada para tu empresa',
    '/solutions/colocation': 'Alojá tus servidores físicos en nuestro datacenter con soporte 24x7',
    '/solutions/sap': 'Nube privada para SAP Business One, SAP ERP y SAP S/4Hana con hardware certificado',
    '/solutions/networking': 'Conectá tus sitios a la nube de forma segura. Consultoría para las redes de tus oficinas',
    '/solutions/cloud_pbx': 'Central telefónica 3CX VoIP en la nube',
};
