import { createTheme } from '@mui/material/styles';
import '@fontsource/nunito/300.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/600.css';
import '@fontsource/nunito/700.css';
import '@fontsource/nunito/900.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/900.css';
import colors from './colors';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Nunito',
            'Montserrat',
            'Verdana',
            'Arial',
        ].join(','),
        h1: {
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '42px',
            color: colors.lightGreen,
            fontFamily: 'Montserrat, Verdana',
        },
        sectionH2: {
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '39px',
            color: colors.lightGreen,
            fontFamily: 'Montserrat',
        },
        subSectionH2: {
            fontFamily: 'Montserrat',
            fontSize: '30px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '37px',
        },
        h2: {
            fontWeight: 700,
            fontSize: '26px',
            lineHeight: '32px',
            color: colors.lightGreen,
            fontFamily: 'Montserrat',
        },
        h3: {
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '30px',
            color: colors.lightGreen,
            fontFamily: 'Montserrat',
        },
        h4: {
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '18px',
            fontFamily: 'Nunito',
        },
        boldH5: {
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '32.4px',
            fontFamily: 'Montserrat',
        },
        h5: {
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            fontFamily: 'Nunito',
        },
        h6: {
            fontWeight: 600,
            fontFamily: 'Nunito',
            fontSize: '16px',
            lineHeight: '24px',
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20.72px',
        },
        subtitle2: {
            fontWeight: 300,
            fontSize: '12px',
            lineHeight: '14px',
        },
        caption: {
            fontWeight: 300,
            fontSize: '10px',
            lineHeight: '12px',
        },
    },
    palette: {
        text: {
            primary: colors.lightGreen,
        },
        primary: {
            main: colors.violet,
        },
        secondary: {
            main: colors.green,
        },
        action: {
            disabled: colors.white,
            disabledBackground: colors.grey,
        },
        info: {
            main: colors.blackGrey,
        },
        slider: {
            main: colors.blueMagenta,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    color: colors.lightGreen,
                    borderRadius: '3px',
                    transition: 'none',
                    fontSize: '16px',
                    fontWeight: 600,
                    fontFamily: 'Nunito',
                },
                outlined: {
                    boxShadow: 'none',
                    color: colors.lightGreen,
                    backgroundColor: 'transparent',
                    padding: '1px 15px',
                    border: `1px solid ${colors.green}`,
                    '&:hover': {
                        boxShadow: 'none',
                        color: colors.green,
                        backgroundColor: 'transparent',
                    },
                    '&:disabled': {
                        color: colors.white,
                        backgroundColor: '#DBDBD5',
                    },
                },
                contained: {
                    boxShadow: 'none',
                    color: colors.white,
                    backgroundColor: colors.green,
                    padding: '1px 15px',
                    border: `1px solid ${colors.green}`,
                    '&:hover': {
                        boxShadow: 'none',
                        color: colors.green,
                        backgroundColor: 'transparent',
                    },
                    '&:disabled': {
                        color: colors.white,
                        backgroundColor: '#DBDBD5',
                    },
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    padding: 100,
                    backgroundColor: colors.darkViolet,
                },
                root: {
                    padding: 100,
                    backgroundColor: colors.darkViolet,
                    '@media (min-width: 600px)': {
                        padding: '0px',
                    },
                },
            },
        },
        MuiLink: {
            root: {
                color: colors.white,
                fontWeight: 'bold',
                '&:disabled': {
                    color: colors.grey,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.violet,
                    borderBottom: `1px solid ${colors.darkViolet}`,
                },
                '& .Mui-selected': {
                    backgroundColor: colors.violet,
                },
                '& .Mui-focusVisible': {
                    backgroundColor: colors.violet,
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.violet,
                    padding: 0,
                    border: `2px solid ${colors.green}`,
                    borderRadius: '2px',
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    overflowY: 'scroll',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: colors.green,
                },
            },
        },
    },
});

export default theme;
