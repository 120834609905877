import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { Link } from 'gatsby';
import colors from '../theme/colors';
import { services, solutions } from '../helpers/home_types';

const dropdownWrapper = {
    position: 'absolute',
    zIndex: 1700,
    backgroundColor: colors.violet,
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.15)',
    width: '963px',
    top: 85,
    left: 95,
};
const dropdownContainer = {
    padding: '19px 41px 31px 41px',
    height: '100%',
};
const halfContainer = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    boxSizing: 'border-box',
};
const dropdownSubtitle = {
    color: colors.lightGreen,
    fontFamily: 'Montserrat',
    lineHeight: '17px',
};

const DropdownItem = (type, item, toggleDropdown, pathname) => {
    const { url_key, name, description } = item;
    const itemLinkPath = `/${type}/${url_key}`;
    return (
        <Link
            to={itemLinkPath}
            style={{ textDecoration: 'none', width: '100%' }}
            key={url_key}
            onClick={() => { if (pathname === itemLinkPath) toggleDropdown(); }}
        >
            <Box mb={2} style={{ width: '100%' }}>
                <Typography variant="boldH5" style={{ color: colors.lightGreen }}>
                    {name}
                </Typography>
                <Typography variant="subtitle1" sx={{ ...dropdownSubtitle }}>
                    {description}
                </Typography>
            </Box>
        </Link>
    );
};

const CustomDropdown = ({
    type,
    toggleIsOpenServicesDropdown,
    toggleIsOpenSolutionsDropdown,
    pathname,
}) => (
    <Box
        sx={(theme) => ({
            [theme.breakpoints.down('lg')]: { display: 'none' },
            ...dropdownWrapper,
        })}
        style={type === 'solutions' ? { height: '230px' } : { height: '370px' }}
    >
        <Grid container sx={{ ...dropdownContainer }}>
            <Grid
                item
                xs={12}
                lg={6}
                sx={{ ...halfContainer }}
                style={{
                    paddingRight: '15px',
                    borderRight: `2px solid ${colors.darkViolet}`,
                }}
            >
                {type === 'services'
                    ? (services.map((service, index) => {
                        if ((services.length / 2) > index) {
                            return (DropdownItem(
                                type, service, toggleIsOpenServicesDropdown, pathname,
                            ));
                        }
                    }))
                    : (solutions.map((solution, index) => {
                        if ((solutions.length / 2) > index) {
                            return (DropdownItem(
                                type, solution, toggleIsOpenSolutionsDropdown, pathname,
                            ));
                        }
                    }))}

            </Grid>
            <Grid
                item
                xs={12}
                lg={6}
                sx={{ ...halfContainer }}
                style={{ paddingLeft: '26px' }}
            >
                {type === 'services'
                    ? (services.map((service, index) => {
                        if ((services.length / 2) <= index) {
                            return (DropdownItem(
                                type, service, toggleIsOpenServicesDropdown, pathname,
                            ));
                        }
                    }))
                    : (solutions.map((solution, index) => {
                        if ((solutions.length / 2) <= index) {
                            return (DropdownItem(
                                type, solution, toggleIsOpenSolutionsDropdown, pathname,
                            ));
                        }
                    }))}
            </Grid>
        </Grid>
    </Box>
);

export default CustomDropdown;
