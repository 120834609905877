export default {
    darkViolet: '#1E1B2C',
    violet: '#301C38',
    electricViolet: '#662482',
    white: '#FFFFFF',
    lightGreen: '#DFFFFD',
    green: '#00A099',
    grey: '#F1F1F1',
    blackGrey: '#727272',
    greyViolet: '#111219',
    cyanBlue: '#1F2838',
    blueMagenta: '#2F2A45',
    cyan: '#55B7B5',
    burgundy: '#2B1C31',
};
