import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import colors from '../theme/colors';

const footerContainer = {
    maxWidth: '1440px',
    width: '100%',
    padding: '50px 136px',
    display: 'flex',
    justifyContent: 'space-between',
};

const Footer = () => (
    <Box
        style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: colors.greyViolet,
        }}
    >
        <Grid
            container
            sx={(theme) => ({ [theme.breakpoints.down('md')]: { display: 'none' }, ...footerContainer })}
        >
            <Grid item md={4}>
                <Box mb={2}>
                    <Link to="/">
                        <StaticImage
                            src="../images/logo.svg"
                            alt="nubi logo"
                            sx={{
                                width: '165px',
                                height: '45px',
                            }}
                        />
                    </Link>
                </Box>
                <Box>
                    <Typography color={colors.white} variant="h5">
                        CABA, Buenos Aires, Argentina
                    </Typography>
                </Box>
            </Grid>
            <Grid
                mt={2}
                item
                md={4}
                px={2}
            >
                <Typography
                    color={colors.green}
                    variant="h5"
                >
                    Negocios
                </Typography>
                <Box mt={2}>
                    <Link to="/services/nubi_compute_service" style={{ textDecoration: 'none' }}>
                        <Typography
                            color={colors.white}
                            variant="h6"
                        >
                            Nubi Compute Service
                        </Typography>
                    </Link>
                    <Link to="/services/flexible_block_storage" style={{ textDecoration: 'none' }}>
                        <Typography
                            color={colors.white}
                            variant="h6"
                        >
                            Flexible Block Storage
                        </Typography>
                    </Link>
                    <Link to="/services/cloud_firewall_and_vpn" style={{ textDecoration: 'none' }}>
                        <Typography
                            color={colors.white}
                            variant="h6"
                        >
                            Cloud Firewall and VPN
                        </Typography>
                    </Link>
                    <Link to="/solutions/private_cloud" style={{ textDecoration: 'none' }}>
                        <Typography
                            color={colors.white}
                            variant="h6"
                        >
                            Private Cloud
                        </Typography>
                    </Link>
                </Box>
            </Grid>
            <Grid
                item
                md={4}
                mt={2}
                sx={{ width: '300px' }}
            >
                <Typography color={colors.green} variant="h5">
                    Seguinos
                </Typography>
                <Box display="flex" justifyContent="space-between" mt={2}>
                    <a href="https://web.facebook.com/Nubi2Go-100681022032914/" target="_blank" rel="noreferrer" alt="Facebook">
                        <Box sx={{ width: '40px' }}>
                            <StaticImage src="../images/facebook_icon.svg" alt="Facebook" />
                        </Box>
                    </a>
                    <a href="https://www.youtube.com/channel/UCyfZNYQ4xqixYHa6kIz88cg" target="_blank" rel="noreferrer" alt="Youtube">
                        <Box sx={{ width: '40px' }}>
                            <StaticImage src="../images/youtube_icon.svg" alt="Youtube" />
                        </Box>
                    </a>
                    <a href="https://www.instagram.com/nubi2go/" target="_blank" rel="noreferrer" alt="Instagram">
                        <Box sx={{ width: '40px' }}>
                            <StaticImage src="../images/instagram_icon.svg" alt="Instagram" />
                        </Box>
                    </a>
                    <a href="https://www.linkedin.com/company/nubi2go/" target="_blank" rel="noreferrer" alt="Linkedin">
                        <Box sx={{ width: '40px' }}>
                            <StaticImage src="../images/linkedin_icon.svg" alt="Linkedin" />
                        </Box>
                    </a>
                </Box>
            </Grid>
        </Grid>
    </Box>
);

export default Footer;
