import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import seoDescriptions from '../helpers/seo_descriptions';

const formatPathnameForTitleTemplate = (pathname, titleTemplate) => {
    if (pathname === '/') return titleTemplate;
    if (pathname === '/about_us') return '%s · Nosotros';
    if (pathname === '/contact_us') return '%s · Contacto';
    if (pathname === '/pricing') return '%s · Precios';
    if (pathname === '/soon') return '%s · Proximamente';

    const formattedTextArray = pathname
        .split(/[\s/_]+/)
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1));
    if (formattedTextArray[0] === '') formattedTextArray.shift();

    const lastWordIndex = formattedTextArray.length - 1;
    if (formattedTextArray[0] === 'Services') {
        formattedTextArray[0] = 'Servicios -';
        if (formattedTextArray[lastWordIndex] === 'Vpc') formattedTextArray[lastWordIndex] = 'VPC';
        if (formattedTextArray[lastWordIndex] === 'Vpn') formattedTextArray[lastWordIndex] = 'VPN';
        if (formattedTextArray[lastWordIndex] === 'Devops') formattedTextArray[lastWordIndex] = 'DevOps';
    }
    if (formattedTextArray[0] === 'Solutions') {
        formattedTextArray[0] = 'Soluciones -';
        if (formattedTextArray[lastWordIndex] === 'Sap') formattedTextArray[lastWordIndex] = 'SAP';
    }

    return `%s · ${formattedTextArray.join(' ')}`;
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;

const SEO = ({
    title, description, image,
}) => {
    const { pathname } = useLocation();
    const { site } = useStaticQuery(query);
    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
        defaultImage,
    } = site.siteMetadata;
    const seo = {
        title: title || defaultTitle,
        description: seoDescriptions[pathname] || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`,
    };
    const formattedTitleTemplate = formatPathnameForTitleTemplate(pathname, titleTemplate);

    return (
        <Helmet
            title={seo.title}
            titleTemplate={formattedTitleTemplate}
            htmlAttributes={{
                lang: 'es',
            }}
        >
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
                <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
                <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <style type="text/css">
                {`
                    html,
                    body {
                        overflow-x: hidden;
                        width: 100%;
                        padding-right: 0 !important;
                    }
                `}
            </style>
        </Helmet>
    );
};
export default SEO;
SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
};
SEO.defaultProps = {
    title: null,
    description: null,
    image: null,
};
