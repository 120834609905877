import React, { useState, cloneElement } from 'react';
import {
    AppBar, Toolbar, useScrollTrigger, Box, Button,
    Menu, MenuItem, Collapse, Typography, Hidden,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'gatsby';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useLocation } from '@reach/router';
import CustomDropdown from './CustomDropdown';
import colors from '../theme/colors';
import { services, solutions } from '../helpers/home_types';
import Logo from '../images/logo.svg';

const DropdownTypes = {
    SERVICES: 'services',
    SOLUTIONS: 'solutions',
};

const headerWrapper = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '20px 136px',
    maxWidth: '1440px',
};
const MenuItemStyle = {
    borderBottom: '1px solid #1E1B2C',
    backgroundColor: colors.violet,
    flexDirection: 'column',
    position: 'relative',
};
const gatsbyLinks = {
    textDecoration: 'none',
    color: colors.lightGreen,
};
const displayOnDesktop = {
    display: {
        lg: 'block',
        xs: 'none',
    },
};
const displayOnMobile = {
    display: {
        lg: 'none',
        xs: 'block',
    },
};
const desktopLinksContainer = {
    minWidth: '514px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
};
const minimumMobileWidth = {
    minWidth: '250px',
};
const dropdownSubtitle = {
    color: colors.lightGreen,
    fontFamily: 'Montserrat',
    lineHeight: '17px',
    marginBottom: '14px',
    maxWidth: '250px',
    whiteSpace: 'pre-line',
};

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const DropdownItem = (type, item) => (
    <Link to={`/${type}/${item.url_key}`} style={{ textDecoration: 'none', width: '100%' }} key={item.url_key}>
        <Typography variant="boldH5" style={{ color: colors.lightGreen }}>
            {item.name}
        </Typography>
        <Typography variant="subtitle1" sx={{ ...dropdownSubtitle }}>
            {item.description}
        </Typography>
    </Link>
);

const MobileMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const isMobileMenuOpen = Boolean(anchorEl);
    const [isServicesSubMenuOpen, setIsServicesSubMenuOpen] = useState(false);
    const [isSolutionsSubMenuOpen, setIsSolutionsSubMenuOpen] = useState(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMobileMenu = () => {
        setAnchorEl(null);
    };
    const toggleIsServicesSubMenuOpen = () => setIsServicesSubMenuOpen(!isServicesSubMenuOpen);
    const toggleIsSolutionsSubMenuOpen = () => setIsSolutionsSubMenuOpen(!isSolutionsSubMenuOpen);
    return (
        <Hidden lgUp>
            <Button
                id="basic-button"
                aria-controls={isMobileMenuOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isMobileMenuOpen ? 'true' : undefined}
                onClick={handleClick}
                aria-label="mobile-menu"
            >
                <MenuIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                getcontentanchorel={null}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={isMobileMenuOpen}
                onClose={handleCloseMobileMenu}
                keepMounted
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    '& .MuiList-root': {
                        border: 'none',
                    },
                    marginTop: '60px',
                    zIndex: 2600,
                }}
            >
                <Box style={{ minWidth: '320px' }}>
                    <MenuItem
                        onClick={() => {
                            toggleIsServicesSubMenuOpen();
                        }}
                        style={{ ...MenuItemStyle }}
                    >
                        <Typography variant="boldH5" style={{ ...minimumMobileWidth, display: 'flex', justifyContent: 'space-between' }}>
                            Servicios
                            {isServicesSubMenuOpen ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                        </Typography>
                        <Collapse
                            in={isServicesSubMenuOpen}
                            timeout="auto"
                            unmountOnExit
                            style={{
                                borderTop: '1px solid #1E1B2C', padding: '17px 20px', ...minimumMobileWidth,
                            }}
                        >
                            {services.map((service) => (DropdownItem('services', service)))}
                        </Collapse>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            toggleIsSolutionsSubMenuOpen();
                        }}
                        style={{ ...MenuItemStyle }}
                    >
                        <Typography variant="boldH5" style={{ ...minimumMobileWidth, display: 'flex', justifyContent: 'space-between' }}>
                            Soluciones
                            {isSolutionsSubMenuOpen ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                        </Typography>
                        <Collapse
                            in={isSolutionsSubMenuOpen}
                            timeout="auto"
                            unmountOnExit
                            style={{ borderTop: '1px solid #1E1B2C', padding: '17px 20px', ...minimumMobileWidth }}
                        >
                            {solutions.map((solution) => (DropdownItem('solutions', solution)))}
                        </Collapse>
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to="/pricing"
                        onClick={handleCloseMobileMenu}
                        style={{ ...MenuItemStyle }}
                    >
                        <Typography variant="boldH5" style={{ ...minimumMobileWidth }}>
                            Precios
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to="/about_us"
                        onClick={handleCloseMobileMenu}
                        style={{ ...MenuItemStyle }}
                    >
                        <Typography variant="boldH5" style={{ ...minimumMobileWidth }}>
                            Nosotros
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to="https://nubi2go.com/docs/"
                        onClick={handleCloseMobileMenu}
                        style={{ ...MenuItemStyle }}
                    >
                        <Typography variant="boldH5" style={{ ...minimumMobileWidth }}>
                            Docs
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to="https://nubi2go.com/community/"
                        onClick={handleCloseMobileMenu}
                        style={{ ...MenuItemStyle }}
                    >
                        <Typography variant="boldH5" style={{ ...minimumMobileWidth }}>
                            Community
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to="/contact_us"
                        onClick={handleCloseMobileMenu}
                        style={{ ...MenuItemStyle }}
                    >
                        <Typography variant="boldH5" style={{ ...minimumMobileWidth }}>
                            Contacto
                        </Typography>
                    </MenuItem>
                    <a
                        href="https://portal.nubi2go.com"
                        target="_blank"
                        rel="noreferrer"
                        alt="Portal Nubi"
                        style={{ ...gatsbyLinks }}
                    >
                        <MenuItem
                            onClick={handleCloseMobileMenu}
                            style={{ ...MenuItemStyle }}
                        >
                            <Typography variant="boldH5" style={{ ...minimumMobileWidth }}>
                                Portal
                            </Typography>
                        </MenuItem>
                    </a>
                </Box>
            </Menu>
        </Hidden>
    );
};

const Header = ({ props }) => {
    const [isOpenServicesDropdown, setIsOpenServicesDropdown] = React.useState(false);
    const [isOpenSolutionsDropdown, setIsOpenSolutionsDropdown] = React.useState(false);
    const toggleIsOpenServicesDropdown = () => {
        if (isOpenSolutionsDropdown) setIsOpenSolutionsDropdown(false);
        setIsOpenServicesDropdown(!isOpenServicesDropdown);
    };
    const toggleIsOpenSolutionsDropdown = () => {
        if (isOpenServicesDropdown) setIsOpenServicesDropdown(false);
        setIsOpenSolutionsDropdown(!isOpenSolutionsDropdown);
    };
    const handleServicesClickAway = () => setIsOpenServicesDropdown(false);
    const handleSolutionsClickAway = () => setIsOpenSolutionsDropdown(false);
    const { pathname } = useLocation();

    return (
        <>
            <ElevationScroll {...props} style={{ padding: 0, display: 'flex', justifyContent: 'center' }}>
                <AppBar style={{ zIndex: 2100 }}>
                    <Toolbar style={{ padding: 0, display: 'flex', justifyContent: 'center' }}>
                        <Box sx={(theme) => ({ ...displayOnDesktop, [theme.breakpoints.down('sm')]: { padding: '24px' }, ...headerWrapper })} py={2}>
                            <Link to="/">
                                <Box style={{ width: '165px' }}>
                                    <img
                                        src={Logo}
                                        alt="nubi logo"
                                    />
                                </Box>
                            </Link>
                            <Box sx={(theme) => ({ ...displayOnDesktop, [theme.breakpoints.up('lg')]: { ...desktopLinksContainer } })}>
                                <ClickAwayListener onClickAway={handleServicesClickAway}>
                                    <Box>
                                        <Button onClick={toggleIsOpenServicesDropdown} aria-label="servicios">
                                            Servicios
                                            <ArrowDropDownIcon />
                                        </Button>
                                        {isOpenServicesDropdown && (
                                            <CustomDropdown
                                                type={DropdownTypes.SERVICES}
                                                toggleIsOpenServicesDropdown={toggleIsOpenServicesDropdown}
                                                pathname={pathname}
                                            />
                                        )}
                                    </Box>
                                </ClickAwayListener>
                                <ClickAwayListener onClickAway={handleSolutionsClickAway}>
                                    <Box>
                                        <Button onClick={toggleIsOpenSolutionsDropdown} aria-label="soluciones">
                                            Soluciones
                                            <ArrowDropDownIcon />
                                        </Button>
                                        {isOpenSolutionsDropdown && (
                                            <CustomDropdown
                                                type={DropdownTypes.SOLUTIONS}
                                                toggleIsOpenSolutionsDropdown={toggleIsOpenSolutionsDropdown}
                                                pathname={pathname}
                                            />
                                        )}
                                    </Box>
                                </ClickAwayListener>
                                <Button>
                                    <Link to="/pricing" style={{ ...gatsbyLinks }} aria-label="precios">
                                        Precios
                                    </Link>
                                </Button>
                                <Button>
                                    <Link to="/about_us" style={{ ...gatsbyLinks }} aria-label="nosotros">
                                        Nosotros
                                    </Link>
                                </Button>
                                <Button>
                                    <Link to="https://nubi2go.com/docs/" style={{ ...gatsbyLinks }} aria-label="docs">
                                        Docs
                                    </Link>
                                </Button>
                                <Button>
                                    <Link to="https://nubi2go.com/community/" style={{ ...gatsbyLinks }} aria-label="community">
                                        Community
                                    </Link>
                                </Button>
                            </Box>
                            <Box sx={{ ...displayOnDesktop }}>
                                <Link to="/contact_us" style={{ ...gatsbyLinks }}>
                                    <Button variant="outlined" style={{ marginRight: '10px' }} aria-label="contacto">
                                        <PhoneIcon />
                                        Contacto
                                    </Button>
                                </Link>
                                <a
                                    href="https://portal.nubi2go.com"
                                    target="_blank"
                                    rel="noreferrer"
                                    alt="Portal Nubi"
                                    style={{ ...gatsbyLinks }}
                                >
                                    <Button variant="contained" aria-label="portal">
                                        <PersonIcon />
                                        Portal
                                    </Button>
                                </a>
                            </Box>
                            <Box sx={{ ...displayOnMobile }}>
                                <MobileMenu />
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
        </>
    );
};

export default Header;
